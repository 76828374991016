import { render, staticRenderFns } from "./additionalOption.vue?vue&type=template&id=f744e120&scoped=true&"
import script from "./additionalOption.vue?vue&type=script&lang=js&"
export * from "./additionalOption.vue?vue&type=script&lang=js&"
import style0 from "./additionalOption.vue?vue&type=style&index=0&id=f744e120&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f744e120",
  null
  
)

export default component.exports