<template>
  <div class="w-full h-full">
    <div
      class="flex justify-center mt-12"
    >
      <div
        class="ml-2 mr-4 mb-5 w-full pl-2 "
      >
        <div class="pt-3">
          <datePicker :value="date" :deseable="['afterToday']" @info="retourInfo"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import datePicker from '@/components/helper/form/datePicker'

export default {
  name: 'index',
  components: {
    datePicker
  },

  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      date: 'Ex: 12/12/1992'
    }
  },

  created () {
    if (this.donnes.naissance !== undefined) {
      this.date = new Date(this.donnes.naissance).toLocaleDateString()
    }
  },

  methods: {
    retourInfo (answer) {
      this.date = answer
      this.$emit('info', answer)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
  .sous-titre{
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    font-family: $font-default;
    color: #909090;
  }
  .titr{
    font-weight: normal;
    font-size: 18px;
    line-height: 27px;
    color: #191919;
  }
</style>
