<template>
  <div class="w-auto h-auto">
    <div class="w-full flex justify-center">
      <svg-icon
        name="devis"
        class="h-24 cursor-pointer"
        original
      />
    </div>
    <div class="titre mt-6"> Votre devis est prêt </div>
  </div>
</template>

<script>

export default {
  name: 'index',
  components: {
  },
  props: {
    label: {
      type: String,
      default: null
    }
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.titre{
  font-family: $font-default;
  font-weight: bold;
  font-size: 28px;
  line-height: 153%;
  color: #263238;
}
@media screen and (max-width: 700px) {
  .titre{
    font-size: 20px;
  }
}
</style>
