<template>
  <div class="bar mr-auto ml-auto">
    <div class="barre h-full" :style="{'width': width}"></div>
  </div>
</template>

<script>

export default {
  name: 'index',
  components: {
  },
  props: {
    width: {
      type: String,
      default: '30%'
    }
  },
  data () {
    return {

    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.bar{
  background: #FFFFFF;
  border-radius: 30px;
  width: 100%;
  height: 10px;
}
.barre{
  background: #0269AD;
  border-radius: 30px;
  width: 20%;
}
</style>
