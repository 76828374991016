<template>
  <div class="w-full h-full">
    <popup-charge :want-to-make-offer="charge" v-if="charge" @oga="charge = false"/>
    <popupSuccess :want-to-make-offer="activeSuccess" @oga="retourSuccess" :message="successText"/>

    <div>
      <div class="baniere flex items-center" >
        <div class="w-2/3 md:pl-6 pl-5">
          <div class="sous-titre">Assurance</div>
          <div class="titre" v-if="type === 'Auto'">Automobile</div>
          <div class="titre" v-if="type === 'Moto'">Moto</div>
          <div class="titre" v-if="type === 'MRH'">MRH</div>
          <div class="titre" v-if="type === 'Voyage'">Voyage</div>
        </div>

        <div class="flex justify-end w-1/3">
          <svg-icon
            v-if="type === 'Auto'"
            name="voiture"
            class="ico"
            original
          />

          <svg-icon
            v-if="type === 'Moto'"
            name="vespa"
            class="ico"
            original
          />

          <svg-icon
            v-if="type === 'MRH'"
            name="house"
            class="ico"
            original
          />

          <svg-icon
            v-if="type === 'Voyage'"
            name="plane"
            class="ico"
            original
          />
        </div>
      </div>

      <div v-if="!status">
        <resultat-auto v-if="type === 'Auto'"/>
        <resultat-moto v-if="type === 'Moto'"/>
        <resultat-mrh v-if="type === 'MRH'"/>
        <resultat-voyage v-if="type === 'Voyage'"/>
      </div>

      <div v-if="status">
        <resultatAutoProrogation v-if="type === 'Auto'"/>
      </div>

      <div class="baniere1 flex items-center mt-5">
        <div class="w-full text-center">
          <div class="sous-titre1">Total a payer</div>
          <div class="titre">{{ allDevis.devis.computed.toLocaleString() }} Fr</div>
        </div>
      </div>

      <div class="w-full mt-2 flex justify-center" @click="actDevis" v-if="!status">
          <div class="button w-2/5 md:mt-5">Modifier mon devis</div>
      </div>

      <div class="w-full mt-2 flex justify-center" @click="kkiaPay" v-if="status">
        <div class="button paye w-full md:mt-5">PAYER</div>
      </div>
    </div>
  </div>
</template>

<script>
import resultatMoto from '@/components/cedro/moto/resultatPrime'
import resultatAuto from '@/components/cedro/automobile/resultatPrime'
import resultatAutoProrogation from '@/components/cedro/automobile/resultatProrogation'
import resultatMrh from '@/components/cedro/mrh/resultatPrime'
import resultatVoyage from '@/components/cedro/voyage/resultatPrime'
import http from '@/plugins/http'
import apiroutes from '@/router/api-routes'
import popupSuccess from '@/components/popup/popupSuccess'
import popupCharge from '@/components/popup/popupCharge'

export default {
  name: 'index',
  components: {
    resultatMoto,
    resultatAuto,
    resultatMrh,
    resultatVoyage,
    resultatAutoProrogation,
    popupSuccess,
    popupCharge
  },

  props: {
    type: {
      type: String,
      default: 'Auto'
    },
    status: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      allDevis: null,
      dataSave: null,
      user: null,
      activeSuccess: false,
      charge: false,
      successText: 'Vous avez souscrit avec succès à une assurance automobile chez nous. Nous vous remercions pour votre confiance'
    }
  },

  mounted () {
    this.allDevis = this.$store.getters.trackFirstDevis

    if (this.status) {
      this.dataSave = this.$store.getters.souscriptionDetail
      // console.log(this.dataSave)
    }

    this.user = this.parseJwt(this.$store.getters.tokenState)
  },

  methods: {
    actDevis () {
      this.$emit('retour', '8343')
    },

    kkiaPay () {
      const lastSave = this.$store.getters.trackFirstDevis
      const demain = new Date()
      demain.setTime(demain.getTime() + 24 * 3600 * 1000)

      this.dataSave.meta.startDate = demain.getTime()
      this.dataSave.meta.createdAt = new Date().getTime()
      this.dataSave.meta.financeData = lastSave.devis

      const body = {
        duration: this.dataSave.duration,
        data: this.dataSave.meta,
        images: this.dataSave.images,
        paid: true,
        productId: this.dataSave.product.id,
        clientId: this.user.id,
        paymentProof: 'string',
        policy: this.dataSave.policyNumber,
        code: this.dataSave.validationCode
      }
      // eslint-disable-next-line no-undef
      openKkiapayWidget({
        amount: lastSave.devis.computed,
        // amount: 1,
        position: 'center',
        callback: '',
        data: body,
        key: 'cd9317d50d7a3794fed6cd64e6440dd277bb93d8'
      })

      // eslint-disable-next-line no-undef
      addSuccessListener(response => {
        body.paymentProof = response.transactionId
        this.save(body)
      })
    },

    save (data) {
      this.charge = true
      http.post(apiroutes.baseURL + apiroutes.renewAssurance + '/auto', data)
        .then(response => {
          console.log('reponsesssss')
          console.log(response)
          this.activeSuccess = true
          this.charge = false
        })
        .catch(error => {
          this.charge = false
          console.log('erreur')
          console.log(error)
        })
    },

    parseJwt (token) {
      const base64Url = token.split('.')[1]
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))
      return JSON.parse(jsonPayload)
    },

    retourSuccess (answer) {
      this.activeSuccess = answer
      this.$router.push('/souscription')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.devTitre{
  font-weight: 500;
  font-size: 24px;
  line-height: 153%;
  color: #000000;
}
.prime{
  font-weight: bold;
  font-size: 27.773px;
  line-height: 37px;
  color: #F44336;
}
.baniere{
  width: 100%;
  border-radius: 20px;
  height: 154px;
  background: linear-gradient(84.41deg, #49BF7C -58.44%, #0269AD 108.79%);
  color: #FFFFFF;
}
.baniere1{
  width: 100%;
  border-radius: 20px;
  height: 102.26px;
  background: #F8E9B8;
  .titre{
    color: #FF9900;
    font-weight: bold;
    font-size: 38px;
  }
  .sous-titre{
    font-size: 14px;
    color: #000000;
  }
}
.button{
  font-weight: 500;
  font-size: 18px;
  line-height: 34px;
  color: #303030;
  height: 60px;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 14px;
}
.bord{
  border: 1px dashed #979797;
  transform: rotate(180deg);
}
div{
  font-family: $font-default;
}
.ico{
  height: 135px;
}
.denom{
  font-style: normal;
  font-weight: normal;
  font-size: 17.6737px;
  line-height: 9px;
  color: #000000;
}
.valeur{
  font-weight: bold;
  font-size: 17.6737px;
  line-height: 53%;
  color: #000000;
}
.reduction{
  .denom{
    font-style: normal;
    font-weight: normal;
    font-size: 15.1489px;
    line-height: 9px;
    color: #F44336;
  }
  .valeur{
    font-weight: bold;
    font-size: 15.1489px;
    line-height: 53%;
    color: #F44336;
  }
}
.sous-titre, .sous-titre1{
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
}

.titre{
  font-weight: bold;
  font-size: 32px;
  line-height: 140%;
}
.button:hover{
  border-color: $base-color;
}
.paye{
  background-color: $base-color;
  color: white;
}
@media screen and (max-width: 700px) {
  .baniere{
    height: 120px;
  }
  .sous-titre1{
    font-size: 12px;
  }
  .sous-titre{
    font-size: 18px;
  }
  .titre{
    font-size: 28px;
  }
}

</style>
