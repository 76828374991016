<template>
  <section class="success">
    <popup-base
      v-show="wantToMakeOffer"
      class="popo md:pt-10 md:pb-4 w-full"
      @clickOnBackground="logState"
    >
      <div class="cardinal mr-auto ml-auto">
        <div
          class="success-container h-full p-6"
        >
          <div class="flex justify-center">
            <svg-icon
              name="valid-green"
              class="h-20"
              original
            />
          </div>

          <div class="titre mt-4">{{ titre }}</div>

          <div class="sous-titre text-center pt-4">
            {{ message }}
          </div>

          <div
            v-if="redirect"
            class="mt-2 font-bold underline text-center text-black"
            @click="goto()"
          >
            {{ redirect }}
          </div>

            <div class="button continuer w-full mt-6" @click="fermer">{{ bouton }}</div>
        </div>
      </div>
    </popup-base>
  </section>
</template>

<script>
import PopupBase from '../helper/add/popupfat'

export default {
  name: 'Success',
  components: { PopupBase },
  props: {
    wantToMakeOffer: {
      type: Boolean,
      default: false
    },
    titre: {
      type: String,
      default: 'Paiement effectué !'
    },
    message: {
      type: String,
      default: ''
    },
    bouton: {
      type: String,
      default: 'OK'
    },
    redirect: {
      type: String,
      default: null
    },
    redirectPath: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      allSteep: ['Devis', 'Identification', 'Complèter...', 'Paiement']
    }
  },
  computed: {
  },

  methods: {
    fermer () {
      this.activeOk = false
      this.$emit('oga', false)
      if (this.redirectPath) {
        this.$router.push('/' + this.redirectPath)
      }
    },
    logState (answer) {
      this.wantToMakeOffer = answer
      this.$emit('oga', false)
      this.activeOk = false
    },

    goto () {
      this.$router.push('/' + this.redirectPath)
    }
  }
}
</script>

<style lang="scss"  scoped>
    @import "../../assets/style/sass/mixin";
    .success{
      z-index: 999;
    }
    .cardinal{
      width: 40%;
      font-family: 'Mulish';
    }

    .success-container {
      font-family: $font-default;
      width: 100%;
      background-color: $white;
      padding: 0px ;
      height: auto;
      margin: auto;
      border-radius: 12px;
    }
    .titre{
      font-weight: 800;
      font-size: 18px;
      line-height: 153%;
      text-align: center;
      color: #00BF53;
    }
    .sous-titre {
      font-weight: normal;
      font-size: 18px;
      line-height: 150%;
      text-align: center;
      color: #606060;
    }

    .button{
      background-color: $base-color;
      height: 57.36px;
      font-weight: 600;
      font-size: 18px;
      line-height: 23px;
      color: $white;
      border: 1px solid #0269AD;
      box-sizing: border-box;
      border-radius: 10px;
    }

    @media screen and (max-width: 1024px) and (min-width: 770px){
      .cardinal{
        width: 65%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }

    @media screen and (max-width: 769px) and (min-width: 701px){
      .cardinal{
        width: 90%;
      }
      .titre{
        font-size: 18px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 19px;
      }
    }
    @media screen and (max-width: 320px){
      .cardinal{
        width: 100%;
      }
      .titre{
        font-size: 14px;
      }
      .button{
        width: 100%;
        height: 55px;
        font-size: 15px;
      }
    }

    @media screen and (max-width: 700px){
      .success-container {
        width: 100%;
        height: 100vh;
      }
      .titre{
        font-size: 18px;
      }
      .bloc1{
        width: 100%;
      }
      .bloc2{
        width: 100%;
        font-size: 18px;
      }
      .button{
        width: 100%;
        font-size: 16px;
      }
      .cardinal{
        width: 100%;
      }
    }
</style>
