<template>
  <div class="w-full h-full hidden">
    <div class="mb-4 font-bold text-black text-[20px]">
      Ajouter des garanties optionnelles ?
    </div>

    <div class="flex justify-between items-center bg-white p-4 mb-3 rounded-lg" v-for="(item, index) in allResultat" :key="index">
      <div class="w-1/2 font-bold">
        {{ item.name }}
      </div>

      <div class="text-right text-green-500 font-bold">
        {{ item.total }} FCFA
      </div>

      <div class="w-1/6">
        <boutton
          v-if="!item.isActive"
          label="Ajouter"
          background="#006fb1"
          size="14px"
          height="35px"
          radius="5px"
          @info="selected(index)"/>

        <boutton
          v-if="item.isActive"
          label="Retirer"
          background="#ffd5d5"
          color="#FF4B55"
          size="14px"
          height="35px"
          radius="5px"
          @info="deleteItem(index)"/>
      </div>
    </div>
  </div>
</template>

<script>
import boutton from '@/components/helper/add/button'

export default {
  name: 'index',
  components: {
    boutton
  },
  data () {
    return {
      allResultat: [
        {
          name: 'Bris de glace',
          total: 2400,
          isActive: false
        },
        {
          name: 'Vol',
          total: 1200,
          isActive: false
        },
        {
          name: 'Vandalisme',
          total: 4000,
          isActive: false
        },
        {
          name: 'Dommages',
          total: 2201,
          isActive: false
        },
        {
          name: 'Avance sur recours',
          total: 300,
          isActive: false
        },
        {
          name: 'Incendie',
          total: 5200,
          isActive: false
        }
      ]
    }
  },

  created () {
    const lastDevis = this.$store.getters.trackFirstDevis
    if (lastDevis.additionalGuarantee) {
      this.selectedItem = lastDevis.additionalGuarantee
    }
  },

  methods: {
    selected (index) {
      this.allResultat[index].isActive = true
      this.saveInStore()
    },

    deleteItem (index) {
      this.allResultat[index].isActive = false
      this.saveInStore()
    },

    saveInStore () {
      const allData = []
      for (const item in this.allResultat) {
        if (this.allResultat[item].isActive) {
          allData.push(this.allResultat[item])
        }
      }

      const lastDevis = this.$store.getters.trackFirstDevis
      console.log('newDev', lastDevis)
      lastDevis.additionalGuarantee = allData
      this.$store.dispatch('saveFirstDevis', lastDevis)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
@media screen and (max-width: 700px) {
}
</style>
