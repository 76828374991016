<template>
  <div class="w-full h-full pt-4">
    <div class="sous-titre md:w-1/2 text-center mb-3 mr-auto ml-auto mt-5">
      Souffrez-vous d’une maladie chronique ?
    </div>

    <div
      class="flex justify-center mt-5 w-full pl-4 pr-4 pt-4"
    >
      <div class="button mr-5" :class="{'non': position === 'oui' }" @click="activer('oui')">Oui</div>
      <div class="button" :class="{'non': position === 'non' }" @click="activer('non')">Non</div>
    </div>

    <div class="sous-titre md:w-1/2 text-center mb-3 mr-auto ml-auto mt-5 pt-4">
      Suivez-vous en ce moment un traitement médical ?
    </div>

    <div
      class="flex justify-center mt-5 w-full pl-4 pr-4 pt-4"
    >
      <div class="button mr-5" :class="{'non': positions === 'oui' }" @click="activers('oui')">Oui</div>
      <div class="button" :class="{'non': positions === 'non' }" @click="activers('non')">Non</div>
    </div>

    <div class="sous-titre md:w-1/2 text-center mb-3 mr-auto ml-auto mt-5 pt-4">
      Suivez-vous des soins dentaires ?
    </div>

    <div
      class="flex justify-center mt-5 w-full pl-4 pr-4 pt-4"
    >
      <div class="button mr-5" :class="{'non': positionDent === 'oui' }" @click="activDent('oui')">Oui</div>
      <div class="button" :class="{'non': positionDent === 'non' }" @click="activDent('non')">Non</div>
    </div>

  </div>
</template>

<script>

export default {
  name: 'index',
  components: {
  },

  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      positionDent: 'non',
      position: 'oui',
      positions: 'oui'
    }
  },

  created () {
    if (this.donnes.sante !== undefined) {
      this.positionDent = this.donnes.sante.dent
      this.position = this.donnes.sante.health
      this.positions = this.donnes.sante.traitment
    }

    this.send()
  },

  methods: {
    activer (index) {
      this.position = index
      this.send()
    },
    activers (index) {
      this.positions = index
      this.send()
    },
    activDent (index) {
      this.positionDent = index
      this.send()
    },
    send () {
      const obj = {
        dent: this.positionDent,
        health: this.position,
        traitment: this.positions
      }
      this.$emit('info', obj)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.input{
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 15.2377px;
  height: 70.09px;
}

div{
  font-family: $font-default;
}
.sous-titre{
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #191919;
}

.button{
  background: #E8E8E8;
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 20px;
  height: 50px;
  width: 15%;
  font-weight: normal;
  font-size: 17.7655px;
  line-height: 27px;
  color: black;
}
.bloc{
  background: #E4E4E4;
  border-radius: 20px;
  .entete{
    font-weight: bold;
    font-size: 18px;
    line-height: 27px;
    color: #FF4B55;
  }
  .response{
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 180%;
    color: #000000;
  }
  .quiz{
    font-weight: normal;
    font-size: 16px;
    line-height: 200%;
    color: #000000;
  }
}
.non{
  background-color: $base-color;
  color: white;
}

input, .neutre{
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  color: #A9B2C6;
  letter-spacing: 4px;
}
.titre{
  font-weight: bold;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  color: #000000;
  font-family: $font-default;
}
.button:hover{
  border: 1px solid $base-color;
}
@media screen and (max-width: 700px) {
  .button{
    width: 25%;
  }
}
</style>
