<template>
  <div class="w-full">
    <div class="compressor">
      <image-compressor
        :done="getFiles"
        :scale="scale"
        :quality="quality"
        ref="compressor"
      >
      </image-compressor>
    </div>
    <div
      class="model input flex items-center w-full pl-4 pr-4"
      @click="openUpload()"
    >
      <div class="md:w-1/5">
        <div
          class="button"
        >
          Ajouter
        </div>
      </div>
      <div class="w-4/5 place">
        {{ valeur }}
      </div>
    </div>

    <div class="mt-3 text-center resp" v-if="valeur !== 'Cliquez ici pour ajouter l’image'">
      {{ valeur }}
    </div>
  </div>
</template>

<script>
import imageCompressor from 'vue-image-compressor'

export default {
  name: 'Index',
  components: {
    imageCompressor
  },

  props: {
    value: {
      type: String,
      default: null
    },
    type: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: 'Cliquez ici pour ajouter l’image\n'
    }
  },

  data () {
    return {
      activeDetail: false,
      selectedItem: null,
      file: null,
      url: null,
      scale: 100,
      quality: 30,
      originalSize: true,
      compressed: null,
      valeur: null
    }
  },

  mounted () {
    if (this.value !== null) {
      this.placeholder = this.value
    }
    this.valeur = this.placeholder
  },

  methods: {
    openUpload () {
      const compressor = this.$refs.compressor.$el
      compressor.click()
    },

    getFiles (obj) {
      this.img = obj.compressed.blob
      this.compressed = obj.compressed

      if (obj.compressed.size !== '0 kB') {
        const tab = []
        tab.push(obj.compressed.file)
        this.file = tab
        this.valeur = tab[0].name
        this.$emit('info', this.file)
      } else {
        this.quality = Math.floor(Math.random() * 40)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";

.model{
  font-weight: 600;
  font-size: 14.1603px;
  line-height: 17px;
  color: black;
}
.compressor{
  display: none;
}
div{
  font-family: $font-default;
}
.place{
  font-weight: normal;
  font-size: 14px;
  line-height: 27px;
  color: #979797;
}
.inputt{
  font-weight: normal;
  font-size: 18px;
  line-height: 160%;
  color: #909090;
  border: none;
  background: transparent;
}

.input{
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 15.2377px;
  height: 70.09px;
  font-family: $font-default;
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  color: #A9B2C6;
}
.resp{
  display: none;
}
.button{
  background-color: #E7F5FF;
  height: 30px;
  border-radius: 8px;
  border: none;
  font-weight: normal;
  font-size: 18px;
  line-height: 160%;
  color: $base-color;
}
.input:focus-within {
  border: 1px solid $focus-color;
}
.input:hover {
  border: 1px solid $hover-color;
}
@media only screen and (max-width: 700px) {
 .place{
   display: none;
 }
  .model{
    justify-content: center;
  }
  .resp{
    display: block;
    font-size: 14px;
  }
}
</style>
