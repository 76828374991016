<template>
  <div class="w-full h-full">
    <div class="bas mt-6 pb-2">
      <div class="flex mb-4">
        <div class="w-1/2 devTitre">Devis prime</div>
        <div class="text-red w-1/2 text-right prime">{{ devis.toLocaleString() }} Fr</div>
      </div>
      <divider height="1.26241px" color="#C4C4C4"/>

      <div v-for="(item, index) in allResultat" :key="index">
        <div class="flex mb-5 mt-5 pt-1 pb-1">
          <div class="w-1/2 denom">
            {{item.name}}
          </div>
          <div class="text-red w-1/2 text-right valeur">
            <span v-if="item.total">{{ item.total.toLocaleString() }}</span>
            <span v-if="!item.total">0</span>
          </div>
        </div>

        <div class="flex mb-4 mt-4 reduction" v-if="item.reduction !== null">
          <div class="w-1/2 denom">
            Réduction ({{ item.reduction.taux }})
          </div>
          <div class="text-red w-1/2 text-right valeur">{{ item.reduction.amount.toLocaleString() }}</div>
        </div>

<!--        <div class="flex mb-4 mt-4 reduction" v-if="item.reduction !== null">-->
<!--          <div class="w-1/2 denom">-->
<!--            Réduction commerciale-->
<!--          </div>-->
<!--          <div class="text-red w-1/2 text-right valeur">5 000</div>-->
<!--        </div>-->

        <div class="bord" v-if="index < allResultat.length - 1"/>
      </div>

      <divider height="6.31204px" color="#0269AD" class="mt-4"/>
    </div>
  </div>
</template>

<script>
import divider from '@/components/helper/add/divider'

export default {
  name: 'index',
  components: {
    divider
  },
  props: {
  },
  data () {
    return {
      devis: 36720,
      oldAssur: false,
      allResultat: [
        {
          name: 'Valeur Prime nette RC',
          total: 333333,
          reduction: {
            taux: '20%',
            amount: '0'
          }
        },
        {
          name: 'Valeur Prime CEDEAO',
          total: 0,
          reduction: null
        },
        {
          name: 'Couverture du Conducteur',
          total: 0,
          reduction: null
        },
        {
          name: 'Accessoires',
          total: 0,
          reduction: null
        },
        {
          name: 'Taxes',
          total: 0,
          reduction: null
        },
        {
          name: 'Valeur Prime FGA',
          total: 0,
          reduction: null
        },
        {
          name: 'Valeur Prime TTC',
          total: 0,
          reduction: null
        }

      ]
    }
  },

  created () {
    const lastSave = this.$store.getters.trackFirstDevis

    if (lastSave.oldAssur === 'Oui') {
      this.oldAssur = true
    }

    if (lastSave.devis.values.ProtectionChauffeur) {
      this.allResultat[2].name = 'Protection Chauffeur'
      this.allResultat[2].total = lastSave.devis.values.ProtectionChauffeur
    } else {
      this.allResultat[2].total = lastSave.devis.values.IndividuelleConducteur
    }

    this.devis = lastSave.devis.computed
    const str1 = lastSave.devis.compute
    const i = str1.indexOf(' ')
    const firstTerm = i === -1 ? str1 : str1.substring(0, i)
    this.allResultat[0].total = parseInt(firstTerm.replace('((', ''))
    this.allResultat[0].reduction.amount = (parseInt(firstTerm.replace('((', '')) * 20) / 100

    this.allResultat[1].total = lastSave.devis.values['$primes-auto-specs.cedeao_si_prorogation']

    this.allResultat[3].total = lastSave.devis.values['$primes-auto-specs.Accessoire_si_prorogation']
    this.allResultat[4].total = lastSave.devis.values['$primes-auto-specs.Taxe_si_prorogation']
    this.allResultat[5].total = lastSave.devis.values['$primes-auto-specs.FGA_si_prorogation']
    this.allResultat[6].total = lastSave.devis.computed
  },

  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.devTitre{
  font-weight: 500;
  font-size: 24px;
  line-height: 153%;
  color: #000000;
}
.prime{
  font-weight: bold;
  font-size: 27.773px;
  line-height: 37px;
  color: #F44336;
}
.baniere{
  width: 100%;
  border-radius: 20px;
  height: 154px;
  background: linear-gradient(84.41deg, #49BF7C -58.44%, #0269AD 108.79%);
  color: #FFFFFF;
}

.bord{
  border: 1px dashed #979797;
  transform: rotate(180deg);
}
div{
  font-family: $font-default;
}

.denom{
  font-style: normal;
  font-weight: normal;
  font-size: 17.6737px;
  line-height: 9px;
  color: #000000;
}
.valeur{
  font-weight: bold;
  font-size: 17.6737px;
  line-height: 53%;
  color: #000000;
}
.reduction{
  .denom{
    font-style: normal;
    font-weight: normal;
    font-size: 15.1489px;
    line-height: 9px;
    color: #F44336;
  }
  .valeur{
    font-weight: bold;
    font-size: 15.1489px;
    line-height: 53%;
    color: #F44336;
  }
}
.sous-titre{
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
}
.titre{
  font-weight: bold;
  font-size: 32px;
  line-height: 140%;
}
@media screen and (max-width: 700px) {
  .denom, .valeur{
    font-size: 14px;
  }
  .devTitre, .prime{
    font-size: 20px;
  }
}
</style>
