<template>
  <div class="w-full h-full">
    <div
      class="flex justify-center mt-12"
    >
      <div
        class="ml-2 mr-4 mb-5 w-full pl-2"
      >
        <div class="sous-titre w-full mb-3">
          Nom complet *
        </div>
        <inputo :value="nom" @info="retourNom" placeholder="AYI Seth"/>
      </div>
    </div>

    <div
      class="flex justify-center"
    >
      <div
        class="ml-2 mr-4 mb-5 w-full pl-2"
      >
        <div class="sous-titre w-full mb-3">
          Numéro du bénéficiaire *
        </div>
        <phone-number placeholder="67 00 00 00" @info="retourtelephone"/>
      </div>
    </div>

    <div
      class="flex justify-center"
    >
      <div
        class="ml-2 mr-4 mb-5 w-full pl-2"
      >
        <div class="sous-titre w-full mb-3">
          Email
        </div>
        <inputo @info="retourEmail" type="email" placeholder="cedric@gmail.com"/>
      </div>
    </div>

    <div
      class="flex justify-center"
    >
      <div
        class="ml-2 mr-4 mb-5 w-full pl-2"
      >
        <div class="sous-titre w-full mb-3">
          Activité
        </div>
        <inputo :value="activity" @info="retourActivity" placeholder="Écrivez pour rechercher"/>
        <selectListe :option="allActivity" @info="retourSearchAct" :value="activity" v-if="activity.length > 0 && selectedActivity !== activity"/>
      </div>
    </div>

    <div
      class="flex justify-center"
    >
      <div
        class="ml-2 mr-4 mb-5 w-full pl-2"
      >
        <div class="sous-titre w-full mb-3">
          Profession
        </div>
        <inputo :value="profession" @info="retourProfession" placeholder="Écrivez pour rechercher"/>
        <selectListe :option="allProfession" @info="retourSearch" :value="profession" v-if="profession.length > 0 && selected !== profession"/>
      </div>
    </div>

    <div
      class="flex justify-center"
    >
      <div
        class="ml-2 mr-4 mb-5 w-full pl-2"
      >
        <div class="sous-titre w-full mb-3">
          Courtier
        </div>
        <multiselect :option="allBroker" :value="broker" @info="retourBroker"/>
      </div>
    </div>

    <div
      class="flex justify-center"
    >
      <div
        class="ml-2 mr-4 mb-5 w-full pl-2"
      >
        <div class="sous-titre w-full mb-3">
          {{fichier}}
        </div>

        <upload
          class="mt-4"
          @info="retourFile"
        />
      </div>
    </div>
    {{donnee}}
  </div>
</template>

<script>
import inputo from '@/components/helper/form/input'
import upload from '@/components/helper/form/upload'
import selectListe from '@/components/helper/add/selectListe'
import profession from '@/components/cedro/jsonWord/profession.json'
import activite from '@/components/cedro/jsonWord/activity.json'
import multiselect from '@/components/helper/form/multiselect'
import brok from '@/components/cedro/jsonWord/intermediates.json'
import phoneNumber from '@/components/helper/form/phoneNumber'

export default {
  name: 'index',
  components: {
    inputo,
    upload,
    selectListe,
    multiselect,
    phoneNumber
  },

  props: {
    fichier: {
      type: String,
      default: 'Ajouter une image de votre passport'
    }
  },

  data () {
    return {
      nom: '',
      telephone: '',
      picture: '',
      profession: '',
      activity: '',
      allProfession: [],
      allActivity: [],
      allBroker: [],
      selected: null,
      selectedActivity: null,
      broker: null,
      email: ''
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    donnee: function () {
      if (this.nom !== '' && this.telephone !== '' && this.picture !== '' && this.profession !== '' && this.activity !== '' && this.email !== '') {
        const obj = {
          fullName: this.nom,
          phone: this.telephone,
          picture: this.picture,
          profession: this.profession,
          activity: this.activity,
          broker: this.broker,
          email: this.email
        }
        this.$emit('info', obj)
      }
    }
  },

  created () {
    this.trackProfession()
    this.trackActivity()
    this.trackBroker()
  },

  methods: {
    retourBroker (answer) {
      this.broker = answer
    },

    retourNom (answer) {
      this.nom = answer
    },

    retourEmail (answer) {
      this.email = answer
    },

    retourtelephone (answer) {
      this.telephone = answer
    },

    retourSearch (answer) {
      this.selected = answer
      this.profession = answer
    },

    retourSearchAct (answer) {
      this.selectedActivity = answer
      this.activity = answer
    },

    retourProfession (answer) {
      this.profession = answer
    },

    retourActivity (answer) {
      this.activity = answer
    },

    retourFile (answer) {
      this.picture = answer
    },

    trackProfession () {
      for (const item in profession) {
        this.allProfession.push(profession[item].label)
      }
    },

    trackActivity () {
      for (const item in activite) {
        this.allActivity.push(activite[item].label)
      }
    },

    trackBroker () {
      const broker = this.$store.getters.trackMyAgence

      if (broker !== null) {
        if (broker.label !== undefined) {
          this.allBroker.push(broker.label)
          this.broker = broker.label
        } else if (broker.agency.label) {
          this.allBroker.push(broker.agency.label)
          this.broker = broker.agency.label
        }
      } else {
        for (const item in brok) {
          this.allBroker.push(brok[item].label)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.input{
  background: #FFFFFF;
  border: 1px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 15.2377px;
  height: 70.09px;
}

div{
  font-family: $font-default;
}

.tiret{
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  color: #A9B2C6;
  letter-spacing: 8.5px;
}
.sous-titre{
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #191919;
}

input, .neutre{
  font-weight: 500;
  font-size: 20px;
  line-height: 34px;
  color: #A9B2C6;
  letter-spacing: 4px;
}
.bas{
  margin-top: -10px;
}
.lettre{
  width: 8%;
}
.numero{
  width: 15%;
}
.sel{
  width: 99%;
}
@media screen and (max-width: 700px){
  .sous-titre{
    font-size: 16px;
  }
  .button{
    width: 30%;
    font-size: 17px;
  }
}
</style>
