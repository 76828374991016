<template>
  <div class="radio w-full">
    <div class="champ flex items-center pl-4 pr-4" @click="openOption">
      <div class="mr-2 w-2/3" v-if="!writeOption">{{value}}</div>

      <div class="mr-2 w-2/3" v-if="writeOption">
        <input v-model="value" type="text" class="w-full h-full">
      </div>

      <div class="w-1/3 flex justify-end">
        <svg-icon
          name="defile"
          class="md:h-3 h-2"
          original
        />
      </div>
    </div>
    <div class="defil">
      <selectListe :option="option" @info="retourSearch" v-if="activeOption"/>
    </div>
    {{ sendValue }}
  </div>
</template>

<script>
import selectListe from '@/components/helper/add/selectListe'

export default {
  name: 'Index',
  components: {
    selectListe
  },
  props: {
    option: {
      type: Array,
      default: null
    },
    index: {
      type: Number,
      default: -2
    },
    writeOption: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: 'Autres'
    }
  },

  data () {
    return {
      activeOption: false,
      temoinValue: null
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sendValue: function () {
      if (this.value !== 'Autres') {
        this.$emit('info', this.value)
      }
      if (this.value !== this.temoinValue) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.activeOption = false
      }
    }
  },

  created () {
    this.temoinValue = this.value
  },

  methods: {
    openOption () {
      this.activeOption = !this.activeOption
    },

    retourSearch (answer) {
      this.selected = answer
      this.value = answer
      this.activeOption = false
      this.position = -1
      this.temoinValue = answer
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.champ{
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 10px;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  font-family: $rubik;
  color: #0e0e0e;
  height: 70px;
}
.svg-fill{
  fill: #fff;
}
.champ:focus-within {
  border: 1px solid $focus-color;
}
.champ:hover {
  border: 1px solid $hover-color;
}
@media screen and (max-width: 700px) {
  .champ{
    height: 49.97px;
    font-size: 16px;
  }
}
</style>
