<template>
  <div class="w-full h-full">
    <div class="flex items-center justify-center mt-6 pt-2">
      <div class="flex justify-center items-center rounded-full md:h-16 md:w-16 h-12 w-12 moins mr-6 cursor-pointer" @click="moins('place')">
        <div class="text-black">-</div>
      </div>

      <div class="inp flex items-center justify-center mr-6">

          <input v-model="value" class="inputo w-12 text-right mr-2">
         <div>jour<span v-if="dure.length > 1">s</span></div>
      </div>

      <div class="flex justify-center items-center rounded-full md:h-16 md:w-16 h-12 w-12 plus cursor-pointer" @click="plus('place')">
        <div class="text-white">+</div>
      </div>
    </div>

    {{ sender }}
  </div>
</template>

<script>

export default {
  name: 'index',
  components: {
  },
  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      dure: [''],
      value: 1
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    sender: function () {
      if (this.dure.length !== this.value) {
        this.$emit('info', this.value)
      }
    }
  },

  mounted () {
    console.log(this.donnes)
    if (this.donnes.dure !== undefined) {
      const tab = []
      let i = 0
      while (i < this.donnes.dure) {
        tab.push('')
        i++
      }
      this.dure = tab
      this.value = this.dure.length
    }
    this.$emit('info', this.dure.length)
  },

  methods: {
    moins () {
      if (this.dure.length > 1) {
        this.dure.splice(0, 1)
      }
      this.value = this.dure.length
      this.$emit('info', this.dure.length)
    },

    plus () {
      this.dure.push('')
      this.value = this.dure.length
      this.$emit('info', this.dure.length)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.inp{
  background: #FFFFFF;
  border: 2.4797px solid #E8E8E8;
  box-sizing: border-box;
  border-radius: 30.9963px;
  height: 77.49px;
  width: 20%;
  font-weight: bold;
  font-size: 24px;
  line-height: 34px;
  text-align: center;
  color: #000000;
}

.titre{
  font-weight: bold;
  font-size: 36px;
  line-height: 48px;
  text-align: center;
  color: #000000;
  font-family: $font-default;
}

div{
  font-family: $font-default;
}
.plus{
  background-color: $base-color;
}
.moins{
  background-color: #E8E8E8;
}
.rounded-full{
  font-weight: normal;
  font-size: 37.1956px;
  line-height: 41px;
  text-align: center;
  color: #000000;
}
@media screen and (max-width: 700px){
  .inp{
    width: 40%;
    height: 59.96px;
    border-radius: 23.9853px;
    font-size: 18px;
  }
  .titre{
    font-size: 24px;
    line-height: 32px;
  }
}
</style>
