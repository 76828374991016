<template>
<div class="about-page w-full h-screen pt-10">
  <div class="barx mr-auto ml-auto" v-if="position < 5">
    <progress-bar :width="totalPourcent"/>
  </div>

  <div class="titre mt-5 pt-1" v-if="position < 5 || position > 6">
    {{allQuiz[position-1].name}}
  </div>

  <steep1 v-if="position === 1" :donnes="responses" @info="retourPays"/>

  <steep2 v-if="position === 2" :donnes="responses" @info="retourDestination"/>

  <div v-if="position === 3">
    <steep3 @info="retourDure" :donnes="responses"/>
  </div>

  <div v-if="position === 4">
    <steep4 @info="retourNaissance" :donnes="responses"/>
  </div>

  <div class="spn" v-if="position === 5 && !activeDevis">
    <div class="w-full flex justify-center items-center h-32">
      <spinner  />
    </div>

    <div class="mt-10 text-center textCharge">
      Calcul de la prime...
    </div>
  </div>

  <div class="spn" v-if="position === 5 && activeDevis">
    <div class="w-full flex justify-center items-center h-full">
      <devis-icons />
    </div>
  </div>

  <div class="resultat md:pl-10 md:pr-10">
    <resultatPrime v-if="position === 6" type="Voyage" @retour="retourDevis"/>
  </div>

  <div v-if="position === 7">
    <depart @info="retourDepart" :donnes="responses"/>
  </div>

  <div v-if="position === 8">
    <sante @info="retourSante" :donnes="responses"/>
  </div>

  <autresInfo v-if="position === 9" @info="retourAutre" :donnes="responses"/>

  <proprietaire v-if="position === 10" :donnes="responses" @info="retourUtilisateur" fichier="Ajouter une image de votre passport"/>

  <div class="error mt-4 text-center" v-if="error !== null && yetError !== null">{{error}}</div>
  {{donnee}}
</div>
</template>

<script>
import steep1 from '@/components/cedro/voyage/steep1'
import progressBar from '@/components/helper/add/progressBar'
import steep2 from '@/components/cedro/voyage/steep2'
import steep3 from '@/components/cedro/voyage/steep3'
import steep4 from '@/components/cedro/voyage/steep4'
import allQuiz from '@/components/cedro/voyage/allQuiz.json'
import spinner from '@/components/helper/add/spinner'
import devisIcons from '@/components/helper/add/devisIcons'
import resultatPrime from '@/components/cedro/devis/devisTemplate'
import proprietaire from '@/components/cedro/voyage/proprietaire'
import depart from '@/components/cedro/voyage/depart'
import sante from '@/components/cedro/voyage/sante'
import http from '@/plugins/http'
import apiroutes from '@/router/api-routes'
import countrie from '@/components/cedro/jsonWord/countries.json'
import autresInfo from '@/components/cedro/voyage/autresInfo'

export default {
  name: 'index',
  components: {
    steep1,
    progressBar,
    steep2,
    steep3,
    steep4,
    spinner,
    devisIcons,
    resultatPrime,
    proprietaire,
    depart,
    sante,
    autresInfo
  },

  props: {
    width: {
      type: String,
      default: '30%'
    },
    retour: {
      type: Number,
      default: null
    },
    suivant: {
      type: Number,
      default: null
    }
  },

  data () {
    return {
      position: 1,
      allQuiz,
      temoinR: 0,
      temoinS: 0,
      activeDevis: false,
      responses: {
        type: 'Voyage'
      },
      error: null,
      yetError: null,
      totalPourcent: '0%'
    }
  },

  computed: {
    // eslint-disable-next-line vue/return-in-computed-property
    donnee: function () {
      this.trackPosition(this.retour, this.suivant)
    }
  },

  created () {
    this.position = this.$store.getters.trackPosition
    if (this.position === 6) {
      this.position = 7
    }
    if (this.position >= 6) {
      this.responses = this.$store.getters.trackFirstDevis
    }
    this.$store.dispatch('product', 'voyage')
    if (this.position > 10) {
      this.$router.push('/recapitulatif')
    }
    this.$store.dispatch('saveSouscription', null)
  },

  methods: {
    retourPays (answer) {
      this.responses.pays = answer
      this.error = null
    },

    retourAutre (answer) {
      this.responses.autre = answer
      this.$store.dispatch('saveFirstDevis', this.responses)
    },

    retourSante (answer) {
      this.responses.sante = answer
      this.error = null
    },

    retourUtilisateur (answer) {
      this.responses.user = answer
      this.error = null
    },

    retourDestination (answer) {
      this.responses.destination = answer
      this.error = null
    },

    retourDepart (answer) {
      this.responses.depart = answer
      this.error = null
    },

    retourDure (answer) {
      this.responses.dure = answer
      this.error = null
    },

    retourNaissance (answer) {
      this.responses.naissance = answer
      this.error = null
    },

    devisRequest () {
      let arrive = null
      let zone = null
      const age = new Date().getFullYear() - new Date(this.responses.naissance).getFullYear()
      for (const item in countrie) {
        if (countrie[item].name === this.responses.destination) {
          arrive = countrie[item].region
          if (arrive === 'Europe' || arrive === 'Africa') {
            zone = 'z1'
            this.responses.zone = 'Zone 1'
          } else {
            zone = 'z2'
            this.responses.zone = 'Zone 2'
          }
        }
      }

      http.post(apiroutes.baseURL + apiroutes.primeVoyage, {
        zone: zone,
        duration: this.responses.dure + 'day',
        age: age.toString()
      })
        .then(response => {
          const lastSave = this.$store.getters.trackFirstDevis
          lastSave.devis = response.data
          lastSave.createdAt = new Date().getTime()
          lastSave.id = 'DV-00' + (this.$store.getters.allDevis.length + 1)
          this.$store.dispatch('saveFirstDevis', lastSave)

          this.activeDevis = true
          setTimeout(() => {
            this.position = 6
            this.activeDevis = false
            this.$emit('steep', 'resultat')
          }, 500)
        })
        .catch(error => {
          console.log('erreur')
          console.log(error)
        })
    },

    retourDevis (answer) {
      this.position = 4
      setTimeout(() => {
        this.error = null
      }, 200)
    },

    trackPosition (retour, suivant) {
      this.error = null
      if (suivant !== this.temoinS) {
        this.yetError = 'ok'
      }
      if (this.position > 1 && retour !== this.temoinR) {
        this.position = this.position - 1
        this.temoinR = retour

        setTimeout(() => {
          this.error = null
        }, 200)
      } else if (suivant !== this.temoinS && this.errorTrack() !== null) {
        this.yetError = null
        this.position = this.position + 1
        this.temoinS = suivant
      } else {
        if (this.position < 5) {
          this.error = 'Veuillez entrer une donnée valide pour continuer'
        }
      }
      this.trackSteep()

      this.responses.position = this.position

      this.$store.dispatch('position', this.position)
      this.$store.dispatch('saveFirstDevis', this.responses)
      const pourcent = (this.position * 100) / 7
      this.totalPourcent = pourcent.toString() + '%'
    },

    errorTrack () {
      if (this.position === 2) {
        if (this.responses.destination !== undefined) {
          return 'ok'
        } else {
          return null
        }
      }
      if (this.position === 1) {
        if (this.responses.pays !== undefined) {
          return 'ok'
        } else {
          return null
        }
      } if (this.position === 3) {
        if (this.responses.dure !== undefined) {
          return 'ok'
        } else {
          return null
        }
      } if (this.position === 4) {
        if (this.responses.naissance !== undefined) {
          return 'ok'
        } else {
          return null
        }
      } if (this.position === 7) {
        if (this.responses.depart !== undefined) {
          return 'ok'
        } else {
          return null
        }
      } if (this.position === 9) {
        if (this.responses.autre !== undefined) {
          return 'ok'
        } else {
          return null
        }
      } if (this.position === 10) {
        if (this.responses.user !== undefined) {
          return 'ok'
        } else {
          return null
        }
      } else {
        return 'ok'
      }
    },

    trackSteep () {
      if (this.position === 3) {
        this.$emit('steep', 'prime')
      }

      if (this.position < 5) {
        this.$emit('steep', 'continuer')
      }

      if (this.position === 5) {
        this.$emit('steep', 'charge')
        this.devisRequest()
      }

      if (this.position > 6) {
        this.$emit('steep', 'afterAnswer')
      }

      if (this.position === 11) {
        this.$router.push('/recapitulatif')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/style/sass/variables";

.about-page{
  width: 50%;
  margin-right: auto;
  margin-left: auto;
  font-family: $font-default;
  background-color: #F5F5F5;
  height: 100%;
  padding-bottom: 300px;
}
.barx{
  width: 95%;
}
.spn{
  width: 100%;
  height: 400px;
}

  .titre{
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 64px;
    text-align: center;
    color: #000000;
  }
  .textCharge{
    font-weight: bold;
    font-size: 28px;
    line-height: 153%;
    color: #263238;
  }
  .num{
    font-weight: bold;
    font-size: 64px;
    line-height: 34px;
    color: rgba(2, 105, 173, 0.1);
  }
  .sous{
    font-weight: bold;
    font-size: 24px;
    line-height: 32px;
    color: #49BF7C;
  }
  .error{
    color: #e36767;
    font-size: 18px;
    line-height: 32px;
  }
@media screen and (max-width: 700px) {
  .about-page {
    width: 90%;
    padding-bottom: 200px;
  }
  .titre{
    font-size: 24px;
    line-height: 32px;
  }
  .textCharge{
    font-size: 20px;
  }
}

@media screen and (max-width: 1024px) and (min-width: 770px){
  .about-page {
    width: 70%;
    padding-bottom: 200px;
  }
}

@media screen and (max-width: 769px) and (min-width: 701px){
  .about-page {
    width: 90%;
    padding-bottom: 200px;
  }
}
</style>
