<template>
  <div class="w-full h-full">
    <div class="bas mt-6 pb-2">
      <div class="flex mb-4">
        <div class="w-1/2 devTitre">Devis prime</div>
        <div class="text-red w-1/2 text-right prime">{{ devis.devis.computed }} Fr</div>
      </div>
      <divider height="1.26241px" color="#C4C4C4"/>

      <div v-for="(item, index) in allResultat" :key="index">
        <div class="flex mb-5 mt-5 pt-1 pb-1">
          <div class="w-1/2 denom">
            {{item.name}}
          </div>
          <div class="text-red w-1/2 text-right valeur">{{item.total}}</div>
        </div>

        <div class="flex mb-4 mt-4 reduction" v-if="item.reduction !== null">
          <div class="w-1/2 denom">
            Réduction ({{ item.reduction.taux }})
          </div>
          <div class="text-red w-1/2 text-right valeur">{{item.reduction.amount}}</div>
        </div>

        <div class="bord" v-if="index < allResultat.length - 1"/>
      </div>

      <divider height="6.31204px" color="#0269AD" class="mt-4"/>
    </div>
  </div>
</template>

<script>
import divider from '@/components/helper/add/divider'

export default {
  name: 'index',
  components: {
    divider
  },
  props: {
  },
  data () {
    return {
      allResultat: [
        {
          name: 'Valeur du bâtiment',
          total: '200 000 000 FCFA',
          reduction: null
        },
        {
          name: 'Loyer mensuel',
          total: '0 FCFA',
          reduction: null
        },
        {
          name: 'Valeur du contenu',
          total: '10 000 000 FCFA',
          reduction: null
        }
      ],
      devis: ''
    }
  },

  created () {
    this.devis = this.$store.getters.trackFirstDevis
    this.allResultat[0].total = this.devis.devis.values.buildingValue

    this.allResultat[1].total = this.devis.devis.values.rent

    this.allResultat[2].total = this.devis.devis.values.contentValue
  },

  methods: {
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.devTitre{
  font-weight: 500;
  font-size: 24px;
  line-height: 153%;
  color: #000000;
}
.prime{
  font-weight: bold;
  font-size: 27.773px;
  line-height: 37px;
  color: #F44336;
}
.baniere{
  width: 100%;
  border-radius: 20px;
  height: 154px;
  background: linear-gradient(84.41deg, #49BF7C -58.44%, #0269AD 108.79%);
  color: #FFFFFF;
}

.bord{
  border: 1px dashed #979797;
  transform: rotate(180deg);
}
div{
  font-family: $font-default;
}

.denom{
  font-style: normal;
  font-weight: normal;
  font-size: 17.6737px;
  line-height: 9px;
  color: #000000;
}
.valeur{
  font-weight: bold;
  font-size: 17.6737px;
  line-height: 53%;
  color: #000000;
}
.reduction{
  .denom{
    font-style: normal;
    font-weight: normal;
    font-size: 15.1489px;
    line-height: 9px;
    color: #F44336;
  }
  .valeur{
    font-weight: bold;
    font-size: 15.1489px;
    line-height: 53%;
    color: #F44336;
  }
}
.sous-titre{
  font-weight: 500;
  font-size: 24px;
  line-height: 140%;
}
.titre{
  font-weight: bold;
  font-size: 32px;
  line-height: 140%;
}
@media screen and (max-width: 700px) {
  .denom, .valeur{
    font-size: 14px;
  }
  .devTitre, .prime{
    font-size: 20px;
  }
}
</style>
