<template>
  <div class="w-full h-full">
    <div
      class="flex justify-center mt-12"
    >
      <div
        class="ml-2 mr-4 mb-5 w-full pl-2 "
      >
        <div class="pt-3">
          <div class="titr mt-2 mb-3">Pays de résidence</div>
          <inputo :value="zone" @info="retourInfo" placeholder="Écrivez pour rechercher"/>
          <selectListe :option="allZone" @info="retourSearch" :value="zone" v-if="zone.length > 0 && selected !== zone"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import inputo from '@/components/helper/form/input'
import selectListe from '@/components/helper/add/selectListe'
import countries from '../jsonWord/countries.json'

export default {
  name: 'index',
  components: {
    inputo,
    selectListe
  },

  props: {
    donnes: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      position: 0,
      allZone: [],
      selected: null,
      zone: ''
    }
  },

  created () {
    this.trackPays()
    if (this.donnes.pays !== undefined) {
      this.selected = this.donnes.pays
      this.zone = this.donnes.pays
    }
  },

  methods: {
    retourInfo (answer) {
      this.zone = answer
    },

    retourSearch (answer) {
      this.selected = answer
      this.zone = answer
      this.$emit('info', answer)
    },

    trackPays () {
      for (const item in countries) {
        this.allZone.push(countries[item].name)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../../assets/style/sass/variables";
.titr{
  font-weight: normal;
  font-size: 18px;
  line-height: 27px;
  color: #191919;
}
</style>
